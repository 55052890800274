import {
	Container,
	Heading,
	Stack,
	Box,
	Flex,
	Grid,
	GridItem,
	Text,
} from "@chakra-ui/react";
import { Link } from "gatsby";
import React from "react";

const QuickLinks = [
	{ name: "Projects", path: "/projects" },
	{ name: "Publications", path: "/Publications" },
	{ name: "Patents", path: "/Patents" },
	{ name: "Startups", path: "/Startups" },
	{ name: "Alumni", path: "/Alumni" },
	{ name: "Books & Blogs", path: "/Books" },
];

const Research = () => {
	return (
		<>
			<Container maxW={'5xl'}>
				<Box my={'7rem'}>
					<Stack direction={{ base: "column", md: "row" }}>
						<Flex
							flex={3}
							justify={"center"}
							align={"center"}
							position={"relative"}
							w={"full"}
						>
							<Box
								width={{ base: "10rem", md: "20rem" }}
								height={{ base: "10rem", md: "20rem" }}
								position={"relative"}
								overflow={"hidden"}
								borderLeft={{base: "0px solid black", md : "6px solid black"}}
								borderBottom={{base: "6px solid black", md : "0px solid black"}}
								align={"center"}
								my={7}
							>
								<Heading justifyContent={"center"} py={{base: '2rem', md: '5rem'}} color={'black'} fontFamily={'merriweather'} fontSize={{base: '2rem', md: '4rem'}}>Quick Links</Heading>
							</Box>
						</Flex>
						<Flex
							flex={2}
							justify={"center"}
							align={"center"}
							position={"relative"}
							w={"full"}
						>
							<Grid
								h="auto"
								mb={10}
								color="white"
								templateRows={{ base: "repeat(3, 1fr)", md: "repeat(2, 1fr)" }}
								templateColumns={{
									base: "repeat(2, 1fr)",
									sm: "repeat(2, 1fr)",
									md: "repeat(3, 1fr)",
								}}
								gap={5}
								justifyItems="center"
							>
								{QuickLinks.map((link) => (
									<Linkbox fontFamily={'poppins'} key={link.name} path={link.path}>
										{link.name}
									</Linkbox>
								))}
							</Grid>
						</Flex>
					</Stack>
				</Box>
			</Container>
		</>
	);
};

export default Research;

const Linkbox = ({children, path, ...rest}) => {
	return (
		<Link to={path}>
			<GridItem
				colSpan={1}
				bg="black"
				width={{ base: "10rem", md: "10rem" }}
				height={{ base: "10rem", md: "10rem" }}
				justifyContent="center"
				boxShadow={"2xl"}
				{...rest}
			>
				<Text color={"white"} fontSize={{base: '13px', md: '13px'}} py={'4.4rem'} px={'2.3rem'}>
					{children}
				</Text>
			</GridItem>
		</Link>
	);
};
