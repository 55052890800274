import React from 'react'
import Layout from '../components/Layout'
import Eventcard from '../components/Events component/Eventcard'

const Events = () => {
  return (
    <Layout>
        <Eventcard/>
    </Layout>
  )
}

export default Events