import React from "react";
import { Container, Box, Heading } from "@chakra-ui/react";
import { useStaticQuery, graphql } from "gatsby";
import ProjectGrid from "./ProjectGrid";

const ProjectsList = () => {
	const data = useStaticQuery(graphql`
		{
			allSanityProjects(sort: { fields: publishedAt, order: DESC }) {
				nodes {
					title
					publishedAt
					slug {
						current
					}
					id
				}
			}
		}
	`);

	const Project = data.allSanityProjects.nodes;



	return (
		<>
			<Container maxW={"6xl"}>
				<Box mt={10} p={5}>
					<Heading fontFamily={'merriweather'} fontWeight={"bold"} fontSize={"3rem"}>
						PROJECTS
					</Heading>
				</Box>
				<ProjectGrid projects={Project} />
			</Container>
		</>
	);
};

export default ProjectsList;
