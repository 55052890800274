import { Box, Container, Heading, Text } from "@chakra-ui/react";
import React from "react";
import Layout from "../../components/Layout";
import Swipers from "./../../components/Events component/EventDetails/Swipers";

const Expo2023 = () => {
  return (
    <Layout>
      <Box my={10}>
        <Heading
          fontSize={"3rem"}
          justifyContent={"center"}
          textAlign={"center"}
        >
          AAC EXPO 2023
        </Heading>
      </Box>

      <Swipers
        img1={
          "https://res.cloudinary.com/aacgriet/image/upload/v1730537988/AAC-web/news_events/expo2023/x8cavlem9zanuaoqvbbs.jpg"
        }
        img2={
          "https://res.cloudinary.com/aacgriet/image/upload/v1730537987/AAC-web/news_events/expo2023/wxvfrd1n979puot2zgny.jpg"
        }
        img3={
          "https://res.cloudinary.com/aacgriet/image/upload/v1730537987/AAC-web/news_events/expo2023/hii5cxqnnt9ekjikleyq.jpg"
        }
        img4={
          "https://res.cloudinary.com/aacgriet/image/upload/v1730537987/AAC-web/news_events/expo2023/lvkx4w5x2uxolt150lmh.jpg"
        }
        img5={
          "https://res.cloudinary.com/aacgriet/image/upload/v1730537987/AAC-web/news_events/expo2023/fnrbjznpwfwttmdv3ufi.jpg"
        }
      />

      <Box>
        <Container maxW={"5xl"} py={10}>
          <Heading fontFamily={"meriweather"} fontSize={"2rem"}>
            AAC is proud to announce its triumph.
          </Heading>
          <Text fontFamily={"poppins"} py={5}>
            Everyone is warmly invited to the highly anticipated 2023 Expo,
            proudly hosted by AAC. This years Expo was a tremendous success,
            showcasing a diverse array of revolutionary innovations that are set
            to transform industries and enhance everyday life. A project reaches
            its pinnacle when it aligns with your aspirations, enabling you to
            flourish alongside it.
            <br />
            <br />
            Expos serve as global platforms designed to tackle the most urgent
            challenges facing humanity today, offering immersive experiences
            that delve into a carefully curated theme through engaging and
            interactive activities.
            <br />
            The 2023 Expo aspires to empower individuals to become architects of
            the future. This involves not only spotlighting solutions to
            pressing societal issues through the dynamic Expo Live programme but
            also inspiring attendees with creative concepts and practical
            approaches to overcome obstacles, thus contributing to the
            collective advancement of humanity.
          </Text>
        </Container>
      </Box>
    </Layout>
  );
};

export default Expo2023;
