import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { Center, Image } from "@chakra-ui/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";

export default function App({ img1, img2, img3, img4, img5 }) {
	return (
		<>
			<Swiper
				pagination={{
					type: "fraction",
				}}
				navigation={true}
				modules={[Pagination, Navigation]}
				className="mySwiper"
			>
				<SwiperSlide>
					<Center>
						<Image
							width={{ base: "90%", md: "60%" }}
							height={{ base: "auto", md: "30rem" }}
							alt="image1"
							src={img1}
						/>
					</Center>
				</SwiperSlide>

				<SwiperSlide>
					<Center>
						<Image
							width={{ base: "90%", md: "60%" }}
							height={{ base: "auto", md: "30rem" }}
							alt="image1"
							src={img2}
						/>
					</Center>
				</SwiperSlide>
				<SwiperSlide>
					<Center>
						<Image
							width={{ base: "90%", md: "60%" }}
							height={{ base: "auto", md: "30rem" }}
							alt="image1"
							src={img3}
						/>
					</Center>
				</SwiperSlide>
				<SwiperSlide>
					<Center>
						<Image
							width={{ base: "90%", md: "60%" }}
							height={{ base: "auto", md: "30rem" }}
							alt="image1"
							src={img4}
						/>
					</Center>
				</SwiperSlide>
				<SwiperSlide>
					<Center>
						<Image
							width={{ base: "90%", md: "60%" }}
							height={{ base: "auto", md: "30rem" }}
							alt="image1"
							src={img5}
						/>
					</Center>
				</SwiperSlide>
			</Swiper>
		</>
	);
}
