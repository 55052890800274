import {
	Box,
	Text,
	Center,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Image,
	Heading,
	Stack,
} from "@chakra-ui/react";
import React from "react";
import Layout from "../components/Layout";

const Startups = () => {
	return (
		<Layout>
			<Center my={20}>
				<Heading fontFamily={"merriweather"}>STARTUPS</Heading>
			</Center>  
			<Box display={"flex"} flexDirection={{base: "column", md: "row"}} mb={20}>
				<Center
					maxW={"5xl"}
					height={{ base: "auto", md: "25rem" }}
					mx={8}
					display={"flex"}
					flexDirection={"column"}
				>
					<Cards
						img={
							"https://res.cloudinary.com/aacgriet/image/upload/c_scale,h_800,w_800/v1664100122/AAC-web/startups/siyalogo_drp799.jpg"
						}
					/>
					<Center mt={4}>
						<Button
							fontSize={"sm"}
							rounded={"full"}
							bg={"blue.400"}
							color={"white"}
							boxShadow={
								"0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
							}
							_hover={{
								bg: "blue.500",
							}}
							_focus={{
								bg: "blue.500",
							}}
						>
							<Basicmodal
								text="Know More"
								name={"SIYA EVENT PLANNERS"}
								image={
									"https://res.cloudinary.com/aacgriet/image/upload/c_scale,w_1800/v1666169002/AAC-web/startups/iPhone_8_-_21_sg4j6q.png"
								}
								reason={
									"There is this stigma in the society that event planning belongs only to the rich communities but we, Siya event planners are here to break that stigma and we provide quite affordable services. Our services include decor, catering, photography, live music, cake delivery, midnight surprises, etc and all our packages can be customized. We make the whole flow of planning an event easier and burden less to our customer!"
								}
								details={
									"Siya event planners make your birthdays, anniversaries and all other special occasions much more unique and memorable. Our services are now available at Warangal, Hyderabad, and Karimnagar. We are passionate and take each one of the events personally and find creative ways to deliver our best to the clients"
								}
								sh={"What Siya does?"}
							/>
						</Button>
					</Center>
				</Center>
				<Center
					maxW={"5xl"}
					height={{ base: "auto", md: "25rem" }}
					mx={8}
					display={"flex"}
					flexDirection={"column"}
				>
					<Cards
						img={
							"https://res.cloudinary.com/aacgriet/image/upload/c_scale,h_800,w_800/v1664100122/AAC-web/startups/skillbattle_apwld9.jpg"
						}
					/>
					<Center mt={4}>
						<Button
							fontSize={"sm"}
							rounded={"full"}
							bg={"blue.400"}
							color={"white"}
							boxShadow={
								"0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
							}
							_hover={{
								bg: "blue.500",
							}}
							_focus={{
								bg: "blue.500",
							}}
						>
							<Basicmodal
								text="Know More"
								name={"SKILL BATTLE"}
								image={
									"https://res.cloudinary.com/aacgriet/image/upload/c_scale,w_1800/v1666169019/AAC-web/startups/iPhone_8_-_31_eus2w6.png"
								}
								details={
									"Skill Battle is a platform that connects art enthusiasts and professionals across the globe. The main aim of Skill Battle is the transformation of a person's hobby into a career. They have a large number of specialized art educators in distinct art forms, where they can exchange their ideas and experience. In SkillBattle, one can participate in different competitions and connect with artists from various communities. It is known for the attractive opportunities that it offers at affordable prices."
								}
								reason={
									"My inspiration for Skillbattle is to create a skill based tomorrow, by equipping people to learn and work with freedom. We wanted to provide art education, art career mentorship and finally equip people to learn and work on what they love to do. Our motto involves creating skill based jobs for our coming generation."
								}
								sh={"What SkillBattle does?"}
							/>
						</Button>
					</Center>
				</Center>
				<Center
					maxW={"5xl"}
					height={{ base: "auto", md: "25rem" }}
					mx={8}
					display={"flex"}
					flexDirection={"column"}
				>
					<Cards
						img={
							"https://res.cloudinary.com/aacgriet/image/upload/c_scale,h_800,w_800/v1664100122/AAC-web/startups/rivachlogo_x4isor.jpg"
						}
					/>
					<Center mt={4}>
						<Button
							fontSize={"sm"}
							rounded={"full"}
							bg={"blue.400"}
							color={"white"}
							boxShadow={
								"0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
							}
							_hover={{
								bg: "blue.500",
							}}
							_focus={{
								bg: "blue.500",
							}}
						>
							<Basicmodal
								text="Know More"
								name={"RIVACH"}
								image={
									"https://res.cloudinary.com/aacgriet/image/upload/c_scale,w_2800/v1666168980/AAC-web/startups/iPhone_8_-_11_vwkmac.png"
								}
								details={
									"Rivach is a B2B product services startup helping founders and entrepreneurs to make their product ideas into digital realities. Rivach has been started by student freelancers to increase the scope of being freelance developers of building a profitable product agency as a team. Solving real time problems as a team has much more impact on the product than solving as solo freelancers. Rivach now is a 8 member team of highly skilled developers and designers experimenting & building all phases of tech products."
								}
								reason={
									"I have always wanted to try different things and having my startup was one among them and because I had a keen interest in programming and have always wanted to put everything I have learned into some real-world application rather than just some theoretical problems. That's when we have decided to start Rivach which provides technical solutions to other startups to scale their business. We aim to provide digital support to as many startups and businesses as possible.-Vamshi Solving real-time tech market problems by working on product strategy, design and development are like playing multiplayer games for me. Doing that as a team has driven me to start Rivach from being a Freelancer working individually.-Rishwanth"
								}
								sh={"What Rivach do?"}
							/>
						</Button>
					</Center>
				</Center>
				<Center
					maxW={"5xl"}
					height={{ base: "auto", md: "25rem" }}
					mx={8}
					display={"flex"}
					flexDirection={"column"}
				>
					<Cards
						img={
							"https://res.cloudinary.com/aacgriet/image/upload/c_scale,h_800,w_800/v1664100121/AAC-web/startups/matkalogo_x4aejm.jpg"
						}
					/>
					<Center mt={4}>
						<Button
							fontSize={"sm"}
							rounded={"full"}
							bg={"blue.400"}
							color={"white"}
							boxShadow={
								"0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
							}
							_hover={{
								bg: "blue.500",
							}}
							_focus={{
								bg: "blue.500",
							}}
						>
							<Basicmodal
								text="Know More"
								name={"MR. MATKA"}
								image={
									"https://res.cloudinary.com/aacgriet/image/upload/c_scale,w_1800/v1666169059/AAC-web/startups/iPhone_8_-_41_jr2wcl.png"
								}
								details={
									"Mr. Matka is a curated food brand, that aims towards the reduction of environmental pollution and preparing delicious food at the same time. The brand believes in providing the food that is of unique taste. The food at Mr. Matka is prepared with utmost care using natural earthen cutlery. It essentially aims towards a healthy environment practice with a touch of tradition which enhances the taste and quality of the food."
								}
								reason={
									"The mother earth is divine & it's our responsibility to conserve it. Keeping the same in mind, I started to create something innovative and also promote clean & green environment. It's the taste in every bit, you get to feel our tradition and the love we serve. Contributing to a fortunate future generation in our own way is our uniqueness which intensified my passion to build this."
								}
								sh={"What Mr.Matka do??"}
							/>
						</Button>
					</Center>
				</Center>
			</Box>
		</Layout>
	);
};

export default Startups;

function Basicmodal({
	text,
	name,
	image,
	details,
	reason,
	sh,
	founder,
	founderd,
}) {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<>
			<Center onClick={onOpen}>{text}</Center>

			<Modal
				blockScrollOnMount={false}
				isOpen={isOpen}
				onClose={onClose}
				size={"full"}
				bg={"#f5f5f5f5"}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader
						fontFamily={"merriweather"}
						align={"center"}
						fontWeight={"bold"}
						fontSize={"5xl"}
					>
						{name}
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Center display={"flex"} flexDirection={"row"}>
							<Stack direction={{base: 'column', md: 'row'}}>
								<Box fontFamily={"poppins"} m={20} p={6}>
									<Center>
										<Image rounded={"10%"} src={image} m={5} />
									</Center>
								</Box>
								<Box>
									<Center>
										<Heading fontFamily={"meriweather"} fontWeight={"medium"}>
											{sh}
										</Heading>
									</Center>
									<Text
										fontFamily={"poppins"}
										fontWeight="bold"
										mb="1rem"
										m={10}
									>
										{details}
									</Text>
									<Center>
										<Heading fontFamily={"meriweather"} fontWeight={"medium"}>
											Reason behind
										</Heading>
									</Center>
									<Text
										fontFamily={"poppins"}
										fontWeight="bold"
										mb="1rem"
										m={10}
									>
										{reason}
									</Text>
								</Box>
							</Stack>
						</Center>
					</ModalBody>
					<ModalFooter>
						<Button
							fontSize={"sm"}
							rounded={"full"}
							bg={"blue.400"}
							color={"white"}
							boxShadow={
								"0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
							}
							_hover={{
								bg: "blue.500",
							}}
							_focus={{
								bg: "blue.500",
							}}
							mr={3}
							onClick={onClose}
						>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

const Cards = ({ img }) => {
	return (
		<Box
			maxW={{ base: "200px", sm: "250px", md: "300px" }}
			boxShadow={"2xl"}
			rounded={"50%"}
			pos={"relative"}
      my={10}
			zIndex={1}
			_hover={{
				cursor: "pointer",
				transition: "all .3s ease",
				transform: "scale(1.03)",
			}}
		>
			<Center>
				<Image
					rounded={"50%"}
					height={"20rem"}
					width={"20rem"}
					boxSize="100%"
					src={img}
					boxShadow={"2xl"}
				/>
			</Center>
		</Box>
	);
};
