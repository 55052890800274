import React from "react";
import {
	Box,
	Container,
	Stack,
	Text,
	Image,
	Flex,
	Heading,
	SimpleGrid,
	StackDivider,
	useColorModeValue,
	List,
	ListItem,
} from "@chakra-ui/react";
import { graphql } from "gatsby";
import Layout from "../../Layout";
import MyPortableText from "../../MyPortableTexr";

export const Pquery = graphql`
	query ProjectQuery($id: String) {
		allSanityProjects(filter: { id: { eq: $id } }) {
			nodes {
				id
				title
				slug {
					current
				}
				publishedAt
				categories
				author
				names
				_rawBody
				mainImage {
					asset {
						altText
						gatsbyImageData
					}
				}
				body {
					_rawChildren
				}
			}
		}
	}
`;

export default function Simple({ data }) {
	const a = data.allSanityProjects.nodes;
	console.log(a[0].names);
	return (
		<Layout>
			<Container maxW={"7xl"}>
				<SimpleGrid
					columns={{ base: 1, lg: 2 }}
					spacing={{ base: 8, md: 10 }}
					py={{ base: 18, md: 24 }}
				>
					<Flex>
						<Image
							rounded={"md"}
							alt={"product image"}
							src={a[0].mainImage.asset.gatsbyImageData.images.fallback.src}
							fit={"cover"}
							align={"center"}
							w={"100%"}
							h={{ base: "100%", sm: "400px", lg: "500px" }}
						/>
					</Flex>
					<Stack spacing={{ base: 6, md: 10 }}>
						<Box as={"header"}>
							<Heading
								lineHeight={1.1}
								fontWeight={600}
								fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
								fontFamily={'merriweather'}
							>
								{a[0].title}
							</Heading>
							<Text
								color={useColorModeValue("gray.900", "gray.400")}
								fontWeight={300}
								fontSize={"2xl"}
								fontFamily={'poppins'}
							>
								{a[0].slug.current}
							</Text>
						</Box>

						<Stack
							spacing={{ base: 4, sm: 6 }}
							direction={"column"}
							divider={
								<StackDivider
									borderColor={useColorModeValue("gray.200", "gray.600")}
								/>
							}
						>
							<Box>
								<Text
									fontSize={{ base: "16px", lg: "18px" }}
									color={useColorModeValue("yellow.500", "yellow.300")}
									fontWeight={"500"}
									textTransform={"uppercase"}
									mb={"4"}
									fontFamily={'merriweather'}
								>
									Project Team members
								</Text>


								<SimpleGrid columns={{ base: 2, md: 2 }} spacing={3}>
								{a[0].names.map((i, index) => {
									return <Text fontFamily={'poppins'} key={index}>{i}</Text>;
									// console.log(i, 'test');
								})}
									{/* <MyPortableText value={a[0].names} ></MyPortableText> */}
								</SimpleGrid>
							</Box>
							<Box>
								<Text
									fontSize={{ base: "16px", lg: "18px" }}
									color={useColorModeValue("yellow.500", "yellow.300")}
									fontWeight={"500"}
									textTransform={"uppercase"}
									mb={"4"}
									fontFamily={'merriweather'}
								>
									Project Details
								</Text>

								<List spacing={2}>
									<ListItem>
										<Text as={"span"} fontWeight={"bold"}>
											DATE:
										</Text>{" "}
										{a[0].publishedAt.substring(0, 10)}
									</ListItem>
									<ListItem>
										<Text as={"span"} fontWeight={"bold"}>
											Team :
										</Text>{" "}
										{a[0].author}
									</ListItem>
									<ListItem>
										<Text as={"span"} fontWeight={"bold"}>
											Domain:
										</Text>{" "}
										{a[0].categories}
									</ListItem>
								</List>
							</Box>
						</Stack>
					</Stack>
				</SimpleGrid>
			</Container>
			<Container maxW={"5xl"}>
				<Stack fontFamily={'poppins'} p={"3"}>
					<Heading fontFamily={'merriweather'}>Project Description</Heading>
					<MyPortableText value={a[0]._rawBody}></MyPortableText>
				</Stack>
			</Container>
		</Layout>
	);
}
