import React from "react";
import Layout from "../components/Layout";
import SmartGlove from "../components/Research Compo/patents/SmartGlove";
import AED from "../components/Research Compo/patents/AED";
import ProjectDetail from "./../components/Research Compo/projects/Projectdetail";
import { Container, Center, Heading, Text } from "@chakra-ui/react";

const Patents = () => {
	return (
		<Layout>
			<Container maxW={"5xl"} my={20}>
				<Center>
					<Heading fontFamily={'merriweather'} fontSize={"3rem"}>Patents</Heading>
				</Center>
				<Text fontFamily={"poppins"} mt={5}>
					A patent is an exclusive right granted for an invention by the
					government to the inventor to make, to use, and to sell an invention
					for a specified number of years. Patent law is one branch of the
					larger legal field known as intellectual property, which also includes
					trademark and copyright law.It centers around the concept of novelty
					and inventive step. The special potential of a patent is accordingly
					that it may be used to prevent all others from including any form of
					invention in their product and services.
				</Text>
			</Container>
			<SmartGlove />
			<AED />
		</Layout>
	);
};

export default Patents;
