import { Box, Container, Heading, Text } from "@chakra-ui/react";
import React from "react";
import Layout from "../../components/Layout";
import Swipers from "./../../components/Events component/EventDetails/Swipers";

const opulence2023 = () => {
  return (
    <Layout>
      <Box my={10}>
        <Heading
          fontSize={"3rem"}
          justifyContent={"center"}
          textAlign={"center"}
        >
          OPULENCE
        </Heading>
      </Box>

      <Swipers
        img1={
          "https://res.cloudinary.com/aacgriet/image/upload/v1730825381/AAC-web/news_events/opulence2023/s1gv2z0j7nzctxmyyrxh.jpg"
        }
        img2={
          "https://res.cloudinary.com/aacgriet/image/upload/v1730825550/AAC-web/news_events/opulence2023/xewna19isjw3rzcdmf5f.jpg"
        }
        img3={
          "https://res.cloudinary.com/aacgriet/image/upload/v1730825381/AAC-web/news_events/opulence2023/elfucxyscblhhnazhxcu.jpg"
        }
        img4={
          "https://res.cloudinary.com/aacgriet/image/upload/v1730825380/AAC-web/news_events/opulence2023/gor2ysygdbqylqjgqybv.jpg"
        }
        img5={
          "https://res.cloudinary.com/aacgriet/image/upload/v1730825381/AAC-web/news_events/opulence2023/zdcnmfzelmh4u20wyr1x.jpg"
        }
      />

      <Box>
        <Container maxW={"5xl"} py={10}>
          <Heading fontFamily={"meriweather"} fontSize={"2rem"}>
            AAC proudly presents Opulence: A Celebration of Innovation and
            Knowledge.
          </Heading>
          <Text fontFamily={"poppins"} py={5}>
            Opulence is an esteemed event hosted by AAC, designed to inspire and
            educate the next generation of innovators and leaders. This year's
            edition witnessed the participation of over 400 students and 5
            faculty members from GRIET, showcasing their enthusiasm for the
            latest trends in technology.
            <br />
            <br />
            The event featured distinguished speakers, including Pranali Bose, a
            seasoned Machine Learning Engineer, Raunak Bagga, the visionary
            Founder and CEO of Koolgen Interactives, and Srikanth Reddy Tenneti,
            the innovative Founder of The Great Honey Company. They shared
            invaluable insights and experiences, enriching the attendees'
            understanding of trending technologies.
            <br />
            Opulence included various activities such as the Ideathon, where 21
            teams presented their inventive ideas, and the G-Prime Coding
            Contest, attracting 42 teams that demonstrated their coding prowess
            through multiple challenging rounds. Additionally, engaging
            workshops on LLMs and GameCraft provided students with hands-on
            experience, further fostering their creativity and problem-solving
            skills.
            <br />
            With its diverse array of activities, Opulence aims to empower
            participants to think critically and innovate boldly, setting the
            stage for future technological advancements and collaboration.
          </Text>
        </Container>
      </Box>
    </Layout>
  );
};

export default opulence2023;
