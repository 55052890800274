import { Box, Container, Heading, Text } from "@chakra-ui/react";
import React from "react";
import Layout from "../../components/Layout";
import Swipers from "./../../components/Events component/EventDetails/Swipers";

const Awards = () => {
	return (
		<Layout>
			<Box my={10}>
				<Heading
					fontSize={"3rem"}
					justifyContent={"center"}
					textAlign={"center"}
				>
					AAC awards ceremony
				</Heading>
			</Box>

			<Swipers
				img1={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085804/AAC-web/news_events/aac%20award/award4_sdipga.jpg"
				}
				img2={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085803/AAC-web/news_events/aac%20award/award2_f2nfyb.jpg"
				}
				img3={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085803/AAC-web/news_events/aac%20award/award3_z3pyxp.jpg"
				}
				img4={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085803/AAC-web/news_events/aac%20award/award1_rpypyb.jpg"
				}
				img5={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085804/AAC-web/news_events/aac%20award/award4_sdipga.jpg"
				}
			/>
			<Box>
				<Container maxW={"5xl"} py={10}>
					<Heading fontFamily={"meriweather"} fontSize={"2rem"}>
						Creativity is thinking up new things. Innovation is doing new
						things.
					</Heading>
					<Text fontFamily={"poppins"} py={5}>
						AAC is a platform for many students with ingenious thinking, who are
						willing to take some initiative in experimentation of the latest
						technologies. Students who complete projects under AAC will be
						certified every year through an 'Award ceremony'.
						<br />
						<br />
						This year, for the first time, the road show of the completed
						projects under AAC has been organised. The road show began with the
						inauguration by the Chief Guest - A.V.V.Prasad,NRSC; Guest of
						Honor-M.G.Sekharam,Chief Administrator of Gokaraju Educational
						Society and Dean of AAC-Dr.Ramamurthy Suri. Projects displayed
						covered booming Domains of today’s world such as Internet of Things,
						Machine Learning, Deep Learning, Drone Technology, Web Development
						etc.
					</Text>
				</Container>
			</Box>
		</Layout>
	);
};

export default Awards;
