import React from "react"
import Achievements from "../components/Homecomponents/Achievements"
import Exploreus from "../components/Homecomponents/Exploreus"
import Drones from "./../components/Homecomponents/Drones"
import Layout from "../components/Layout"
import { Box } from "@chakra-ui/react"


// markup
const IndexPage = () => {

	return (
		<Layout>
			<Box
				as="video"
				autoPlay
				loop
				muted
				width={"100%"}
				height={"92vh"}
				alt="home video"
				objectFit={"cover"}
				src={
					"https://res.cloudinary.com/rivach/video/upload/v1660155620/Brekrr/aac-video_aierli.mp4"
				}
			/>
			<Exploreus />
			<Achievements />
			<Drones />
		</Layout>
	);
};

export default IndexPage;
