import { Box, Container, Heading, Text } from "@chakra-ui/react";
import React from "react";
import Layout from "../../components/Layout";
import Swipers from "./../../components/Events component/EventDetails/Swipers";

const Expo2022 = () => {
	return (
		<Layout>
			<Box my={10}>
				<Heading
					fontSize={"3rem"}
					justifyContent={"center"}
					textAlign={"center"}
				>
					AAC EXPO 2022
				</Heading>
			</Box>

			<Swipers
				img1={
					"https://res.cloudinary.com/aacgriet/image/upload/v1667145729/AAC-web/news_events/expo2022/DSC_0020_r9zqem.jpg"
				}
				img2={
					"https://res.cloudinary.com/aacgriet/image/upload/v1667145731/AAC-web/news_events/expo2022/DSC_0008_rrbc8y.jpg"
				}
				img3={
					"https://res.cloudinary.com/aacgriet/image/upload/v1667145731/AAC-web/news_events/expo2022/DSC_0462_kfsrq6.jpg"
				}
				img4={
					"https://res.cloudinary.com/aacgriet/image/upload/v1667145730/AAC-web/news_events/expo2022/DSC_0569_d1dg8n.jpg"
				}
				img5={
					"https://res.cloudinary.com/aacgriet/image/upload/v1667145733/AAC-web/news_events/expo2022/DSC_0383_cqn39u.jpg"
				}
			/>

			<Box>
				<Container maxW={"5xl"} py={10}>
					<Heading fontFamily={"meriweather"} fontSize={"2rem"}>
						AAC has succeeded in proving so.
					</Heading>
					<Text fontFamily={"poppins"} py={5}>
						Everyone is invited to the 2022 expo, says AAC. The 2022 Expo was a
						success, featuring a variety of cutting-edge innovations. A project
						is complete when it starts working for you, rather than you working
						for it.
						<br />
						<br />
						Expos are international gatherings with the goal of discovering
						answers to the most important problems that humanity is currently
						experiencing by providing a trip inside a selected theme through
						exciting and immersive activities.
						<br />
						This Expo wants to give individuals the power to influence the
						future. This involves promoting solutions to societal concerns
						through the Expo Live programme and inspiring visitors with concepts
						for how to address problems and contribute to the advancement of
						humanity.
					</Text>
				</Container>
			</Box>
		</Layout>
	);
};

export default Expo2022;
