import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {
	Box,
	Container,
	Heading,
	Text,
	Stack,
	Center,
	Image,
	Button,
} from "@chakra-ui/react";
import "./style.css";
import { Mousewheel, Keyboard } from "swiper";
import { Link } from "gatsby";

const Eventcard = () => {
	return (
		<>
			<Box my={20}>
				<Container my={20}>
					<Center>
						<Heading fontFamily={"merriweather"} fontSize={"4rem"}>
							Events
						</Heading>
					</Center>
				</Container>
				<Swiper
					grabCursor={true}
					mousewheel={true}
					keyboard={true}
					modules={[Mousewheel, Keyboard]}
					className={"swiperMobile"}
					breakpoints={{
						499: {
							slidesPerView: 1,
							spaceBetweenSlides: 30,
						},
						// when window width is <= 999px
						999: {
							slidesPerView: 3,
							spaceBetweenSlides: 40,
						},
					}}
				>   <SwiperSlide>
						<ProductSimple
							event={"Opulence 2023"}
							path={"/events/Opulence2023"}
							date={"JUNE 2024"}
							img={
								"https://res.cloudinary.com/aacgriet/image/upload/v1730825402/AAC-web/news_events/opulence2023/r8lt4zd7x5bmvmcvytqh.jpg"
							}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<ProductSimple
							event={"AAC Expo 2023"}
							path={"/events/Expo2023"}
							date={"DEC 2023"}
							img={
								"https://res.cloudinary.com/aacgriet/image/upload/v1730826047/AAC-web/news_events/expo2023/mskbwi5ieigki5qactl0.jpg"
							}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<ProductSimple
							event={"AAC Expo 2022"}
							path={"/events/Expo2022"}
							date={"OCT 2022"}
							img={
								"https://res.cloudinary.com/aacgriet/image/upload/v1730826433/AAC-web/news_events/expo2022/wgrd6p6gnst2pqropeht.jpg"
							}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<ProductSimple
							event={"AAC Lab Inauguration"}
							path={"/events/Labstart"}
							date={"JUNE 2020"}
							img={
								"https://res.cloudinary.com/aacgriet/image/upload/c_scale,h_350,w_350/v1664100159/AAC-web/news_events/inauguration_xwgkz3.jpg"
							}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<ProductSimple
							event={"Conclave Data Analytics"}
							date={"April 2020"}
							path={"/events/Conclave"}
							img={
								"https://res.cloudinary.com/aacgriet/image/upload/c_scale,h_350,w_350/v1664100157/AAC-web/news_events/dataconclave_ienjzt.jpg"
							}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<ProductSimple
							event={"Cyber Secuirty Seminar"}
							path={"/events/CyberSecuritySeminar"}
							date={"May 2020"}
							img={
								"https://res.cloudinary.com/aacgriet/image/upload/c_scale,h_350,w_350/v1664100161/AAC-web/news_events/inauguration8_okhjsl.jpg"
							}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<ProductSimple
							event={"Award Function"}
							path={"/events/Awards"}
							date={"Jan 2020"}
							img={
								"https://res.cloudinary.com/aacgriet/image/upload/c_scale,h_350,w_350/v1664100188/AAC-web/news_events/Virtual_Award_Ceremony_bxj2xj.png"
							}
						/>
					</SwiperSlide>
				</Swiper>
			</Box>
		</>
	);
};

export default Eventcard;

const ProductSimple = ({ event, date, path, img }) => {
	return (
		<Box>
			<Center>
				<Stack className="flip-card">
					<Stack className="flip-card-inner">
						<Stack className="flip-card-front">
							<Center pt={7}>
								<Image opacity={"30%"} rounded={"full"} src={img} />
							</Center>
						</Stack>
						<Stack className="flip-card-back">
							<Heading fontSize={40}>{date}</Heading>
							<Text pt={20} fontSize={30}>
								{event}
							</Text>
							<Link to={path}>
								<Button color={"black"} bg={"white"} mt={5} py={5}>
									Explore
								</Button>
							</Link>
						</Stack>
					</Stack>
				</Stack>
			</Center>
		</Box>
	);
};
