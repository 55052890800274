import React from 'react'
import Layout from '../components/Layout'
import Advisory from "./../components/Admin/Advisory";
import Facultyadvisor from "./../components/Admin/Facultyadvisor";
import Mentors from "./../components/Admin/Mentors";

const Administration = () => {
  return (
    <Layout>
        <Facultyadvisor/>
        <Advisory/>
        <Mentors/>
    </Layout>
  )
}

export default Administration