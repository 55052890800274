import { Box, Container, Heading, Text } from "@chakra-ui/react";
import React from "react";
import Layout from "../../components/Layout";
import Swipers from "./../../components/Events component/EventDetails/Swipers";

const Conclave = () => {
	return (
		<Layout>
			<Box my={10}>
				<Heading
					fontSize={"3rem"}
					justifyContent={"center"}
					textAlign={"center"}
				>
					Data analytics conclave
				</Heading>
			</Box>

			<Swipers
				img1={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085800/AAC-web/news_events/conclave%20data%20analytics/dataconclave2_z2ywmk.jpg"
				}
				img2={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085800/AAC-web/news_events/conclave%20data%20analytics/dataconclave5_c7y3bg.jpg"
				}
				img3={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085801/AAC-web/news_events/conclave%20data%20analytics/dataconclave4_kmen6q.jpg"
				}
				img4={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085800/AAC-web/news_events/conclave%20data%20analytics/dataconclave3_v0oibd.jpg"
				}
				img5={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085799/AAC-web/news_events/conclave%20data%20analytics/dataconclave1_vauoxi.jpg"
				}
			/>
			<Box>
				<Container maxW={"5xl"} py={10}>
					<Heading fontFamily={"meriweather"} fontSize={"2rem"}>
						“IF YOU ARE TALKING DATA SCIENCE,THERE IS NO SOLUTION,THERE ARE ONLY
						PROBLEMS AND DATA IS MONEY TODAY.” -SATISH YELLANKI
					</Heading>
					<Text fontFamily={"poppins"} py={5}>
						AAC GRIET had organized a conclave at the GRIET campus on 17th
						August 2019, the theme of the session being “DATA SCIENCE". The
						conclave proved to be an impactful platform where an array of
						inspirational group of people were brought together, led by a data
						scientist with cutting edge entrepreneurial skills-Mr. Satish
						Yellanki.
						<br />
						<br />
						Sathish Yellanki is having 20+ Years of I.T. Exposure in Various
						Areas of Computer Science Industry. He has been part of Relational,
						Object Relational And Object Oriented Databases in Design, Analysis
						and Coding.He has also designed various Domains Like Health Care,
						Education, Retail And E-Commerce. Mr.Satish has further extended his
						research to Data Ware Housing and Business Intelligence domains. He
						is an expert in System Analysis and Design with Relational And
						Dimensional Modeling and worked in critical areas like Performance,
						Tuning And Optimization of Oracle Database.
					</Text>
				</Container>
			</Box>
		</Layout>
	);
};

export default Conclave;
