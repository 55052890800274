import React from 'react'
import Layout from '../components/Layout'
import New from '../components/news page/NewsList'

const News = () => {
  return (
    <Layout>
        <New/>
    </Layout>
  )
}

export default News