import React from "react";
import { GridItem, Heading, Text, Button, Icon, Flex } from "@chakra-ui/react";
import { IoAnalyticsSharp } from "react-icons/io5";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "gatsby";

const ProjectCard = ({ title, date, path }) => {
	return (
		<>
			<GridItem
				colSpan={1}
				bg="blue.200"
				width={{ base: "80vw", md: "18vw" }}
				rounded={"2xl"}
				justifySelf="center"
				color={"black"}
				boxShadow={"2xl"}
			>
				<Flex
					w={8}
					h={8}
					align={"center"}
					justify={"center"}
					rounded={"full"}
					bg={"yellow.100"}
					m={5}
				>
					<Icon as={IoAnalyticsSharp} color={"yellow.500"} w={5} h={5} />
				</Flex>
				<Link to={`/projects/${path}`}>
					<Heading fontFamily={'merriweather'} fontSize={"1rem"} px={5}>
						{title}
					</Heading>
				</Link>
				<Text p={5} color={"black"}>
					{" "}
					{date}
				</Text>
			</GridItem>
		</>
	);
};

export default ProjectCard;
