import {
	Box,
	Container,
	Text,
	Flex,
	Center,
	Link,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	textDecoration,
} from "@chakra-ui/react";
import React from "react";

const Exploreus = () => {
	return (
		<Box my={6}>
			<Container
				justify={{ base: "center", md: "center" }}
				textAlign={{ base: "center", md: "center" }}
				width="100%"
			>
				<Text
					fontWeight="600"
					textTransform="uppercase"
					fontFamily="Questrial"
					fontSize={{ base: "3xl", sm: "5xl" }}
					pl={7}
					width="100%"
				>
					Explore us
				</Text>
			</Container>
			<Container width={"100%"}>
				<Flex
					direction={["column", "row"]}
					gap={{ base: "30", sm: "50" }}
					justify={["center"]}
					align={["center", "center"]}
					my={8}
					mb={10}
				>
					<Link
						textDecoration={"none"}
						_hover={{
							textDecoration: "none",
						}}
					>
						<Center
							w={{ base: " 250px", sm: "100px", md: "200px" }}
							h="150px"
							bg="white"
							textDecoration={"none"}
							fontSize="2xl"
							borderBottom={"3px solid #172E7C"}
							boxShadow="2xl"
							fontFamily={"merriweather"}
							p="6"
							fontWeight="480"
							_hover={{
								bg: "gray.100",
								fontWeight: "bold",
								textDecoration: "none",
							}}
						>
							<Basicmodal text="Vision and Mission" body={"Advanced Academic Center (AAC) aspires to be a pre-eminent and inclusive student-focused research unit, preparing young minds in design thinking and innovation for societal problems.As an innovation center with the capacity to attract and engage the best talent in the institute. A culture of innovation and discovery is cultivated by supporting experiential learning and entrepreneurial endeavors among students."} />
						</Center>
					</Link>
					<Link
						textDecoration={"none"}
						_hover={{
							textDecoration: "none",
						}}
					>
						<Center
							w={{ base: " 250px", sm: "100px", md: "200px" }}
							h="150px"
							bg="white"
							mt={{ base: "0px", md: "90px" }}
							ml={{ base: "50px", md: "0px" }}
							fontSize="2xl"
							borderBottom={"3px solid #172E7C"}
							boxShadow="2xl"
							fontFamily={"merriweather"}
							p="6"
							fontWeight="480"
							_hover={{
								bg: "gray.100",
								fontWeight: "bold",
							}}
						>
							<Basicmodal
								text="Who are we?"
								body={
									"The Advanced Academic Center (AAC) of GRIET, Hyderabad is an inter-disciplinary research centre. We are committed to excellence in teaching, learning, and research at AAC, and we continue to flourish in this modern age. Focusing mainly on a broad area of research and development, bringing together experts with diverse backgrounds to address aspects of that problem area, along with the continuous advancements in science"
								}
							/>
						</Center>
					</Link>
					<Link
						textDecoration={"none"}
						_hover={{
							textDecoration: "none",
						}}
					>
						<Center
							w={{ base: " 250px", sm: "100px", md: "200px" }}
							h="150px"
							bg="white"
							fontSize="2xl"
							borderBottom={"3px solid #172E7C"}
							boxShadow="2xl"
							fontFamily={"merriweather"}
							p="5"
							fontWeight="480"
							_hover={{
								bg: "gray.100",
								fontWeight: "bold",
							}}
						>
							<Basicmodal
								text="What do we offer?"
								body={
									"Students are engaged in designing and executing projects with AAC, we organize  workshops and lectures on specialized engineering software and hardware tools. Offering an unparalleled student experience and a generous financial aid program as well. Along with this we also provide mentorship from real time industrial experts as well as Sponsored / Consultancy R&D projects as well. Students at AAC are encouraged to learn scientific and technical skills ,to explore the various fields present and help with society's needs in the future."
								}
							/>
						</Center>
					</Link>
				</Flex>
			</Container>
		</Box>
	);
};

export default Exploreus;

function Basicmodal({ text, body }) {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<>
			<Center onClick={onOpen}>{text}</Center>

			<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader fontFamily={"merriweather"}>{text}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text fontFamily={"poppins"} mb="1rem">
							{body}
						</Text>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
