import React from 'react'
import Layout from '../components/Layout'
import PublicationsCard from '../components/Research Compo/publications/PublicationsCard'

const Publications = () => {
  return (
    <Layout>
        <> 
          <PublicationsCard/>
        </>
    </Layout>
  )
}

export default Publications