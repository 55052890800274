import React, { useState } from "react";
import { Container, Box, Heading, Stack, Grid } from "@chakra-ui/react";
import ProjectCard from "./ProjectCard";
import { FaSearch } from "react-icons/fa";

const ProjectGrid = ({ projects }) => {
	const [Search, setSearch] = useState("");
	return (
		<>
			<Stack>
				<Box direction={{ base: "column", md: "row" }}>
					<Box p={5}>
						<Stack direction={"row"}>
						<Box pt={3.5}>
								<FaSearch size={"1.3rem"} fontWeight={"normal"} />
							</Box>
							<input
								style={{
									padding: "10px",
									border: "2px #172E7C solid",
									borderRadius: "20px",
								}}
								placeholder={"Search"}
								onChange={(event) => {
									setSearch(event.target.value);
								}}
							/>
							
						</Stack>
					</Box>
					<Grid
						h="auto"
						mb={10}
						color="white"
						templateRows={{ base: "repeat(3, 1fr)", md: "repeat(1, 1fr)" }}
						templateColumns={{
							base: "repeat(1, 1fr)",
							md: "repeat(4, 1fr)",
						}}
						gap={5}
						justifyItems="center"
					>
						{projects &&
							projects
								.filter((project) => {
									if (Search == "") {
										return project;
									} else if (
										project.title.toLowerCase().includes(Search.toLowerCase())
									) {
										return project;
									}
								})
								.map((project) => (
									<ProjectCard
										key={project.id}
										path={project.id}
										title={project.title}
										date={project.publishedAt.substring(0, 10)}
									/>
								))}
					</Grid>
				</Box>
			</Stack>
		</>
	);
};

export default ProjectGrid;
