import { Box, Container, Text, Flex, Image } from "@chakra-ui/react";
import React from "react";

const Achievement = ({ src }) => {
	return <Image rounded={'xl'} boxShadow={'2xl'} src={src} height="250px" width="350px" />;
};

const Achievements = () => {
	return (
		<Box mt={9} py={10} bg="#88B0D7">
			<Container
				justify={{ base: "center", md: "center" }}
				textAlign={{ base: "center", md: "center" }}
				width="100%"
			>
				<Text
					fontWeight="600"
					textTransform="uppercase"
					fontFamily="Questrial"
					fontStyle={"regular"}
					fontSize={{ base: "3xl", sm: "5xl" }}
					pl={7}
					width="100%"
					color={"white"}
				>
					Achievements
				</Text>
			</Container>

			<Flex
				direction={["column", "row"]}
				gap={55}
				justify={["center"]}
				align={["center", "center"]}
				my={10}
				pt={8}
				mb={10}
			>
				<Box>
					<Achievement
						src={
							"https://res.cloudinary.com/aacgriet/image/upload/c_scale,h_400,w_500/v1664100184/AAC-web/news_events/vishwakarma_zzzwlh.jpg"
						}
					/>
					<Text
						color={"black"}
						justify={["center"]}
						align={["center", "center"]}
						lineHeight={"60px"}
						fontWeight="600"
						fontSize={{ base: "20px", sm: "25px", md: "20px" }}
						fontStyle={"Montserrat"}
					>
						Vishwakarma Award 2018
					</Text>
				</Box>
				<Box>
					<Achievement
						src={
							"https://res.cloudinary.com/aacgriet/image/upload/c_scale,h_400,w_500/v1664100167/AAC-web/news_events/nrsc5_e8it62.jpg"
						}
					/>
					<Text
						color={"black"}
						justify={["center"]}
						align={["center", "center"]}
						lineHeight={"60px"}
						fontWeight="600"
						fontSize={{ base: "20px", sm: "25px", md: "20px" }}
						fontStyle={"Montserrat"}
					>
						Signing an MOU with NRSC
					</Text>
				</Box>
				<Box>
					<Achievement
						src={
							"https://res.cloudinary.com/aacgriet/image/upload/c_scale,h_400,w_500/v1664100162/AAC-web/news_events/Juniorshackathon2_opwpyj.jpg"
						}
					/>
					<Text
						color={"black"}
						justify={["center"]}
						align={["center", "center"]}
						lineHeight={"60px"}
						fontWeight="600"
						fontSize={{ base: "20px", sm: "25px", md: "20px" }}
						fontStyle={"Montserrat"}
					>
						Several Hackathons winners
					</Text>
				</Box>
			</Flex>
		</Box>
	);
};

export default Achievements;
