import { Container, Box, Heading, Stack, Grid} from "@chakra-ui/react";
import React from "react";
import Layout from "../components/Layout";
import ProjectCard from "../components/Research Compo/projects/ProjectCard";
import Projectdetail from "../components/Research Compo/projects/Projectdetail";
import ProjectsList from "../components/Research Compo/projects/ProjectsList";

const projects = () => {
	return (
		<>
			<Layout>
				<ProjectsList />
			</Layout>
		</>
	);
};

export default projects;
