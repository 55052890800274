import {
	Box,
	Container,
	Stack,
	Image,
	Center,
	Heading,
	Text,
} from "@chakra-ui/react";
import React from "react";
import { graphql } from "gatsby";
import Layout from "../Layout";
import MyPortableText from "../MyPortableTexr";

export const Nquery = graphql`
	query NewsQuery($id: String) {
		allSanityNews(filter: { id: { eq: $id } }) {
			nodes {
				title
				publishedAt
				categories
				mainImage {
					asset {
						altText
						gatsbyImageData
					}
				}
				slug {
					current
				}
				_rawBody
				id
			}
		}
	}
`;

const Newsdetailed = ({ data }) => {
	const a = data.allSanityNews.nodes[0];

	return (
		<Layout>
			<Box>
				<Container maxW={"5xl"}>
					<Stack p={"5"} justifyContent={"center"} mt={"3rem"}>
						<Heading fontSize={"1rem"} fontWeight={"500"}>
							Published on: {a.publishedAt.substring(0, 10)}
						</Heading>

						<Center>
							<Image
								src={a.mainImage.asset.gatsbyImageData.images.fallback.src}
								alt={"news image"}
								w={"100%"}
								height={{sm: 'auto', md: '35rem'}}
							/>
						</Center>
					</Stack>
					<Stack justifyContent={"center"} my={"2rem"}>
						<Heading fontFamily={'poppins'} fontSize={"3rem"} textAlign={"center"}>
							{a.title}
						</Heading>
						<Heading fontSize={"1.3rem"}>{a.slug.current}</Heading>
						<MyPortableText value={a._rawBody} ></MyPortableText>
						{/* {" "}
							{a._rawBody.map(i => {
								<Text key={i}>
									{i.children[0].text} 
								</Text>
							})} */}
						<Heading pt={"2rem"} fontSize={"1.3rem"}>
							Categories
						</Heading>
						<Text>{a.categories ? a.categories : "NOTICE"}</Text>
					</Stack>
				</Container>
			</Box>
		</Layout>
	);
};

export default Newsdetailed;
