import React from 'react'
import Layout from '../components/Layout'
import ResearchOne from '../components/Research Compo/ResearchOne'

const Research = () => {
  return (
    <Layout>
        <ResearchOne/>
    </Layout>
  )
}

export default Research