export default [
    {
      id: "1",
      name: "Dr. S.N.N.Pandit",
      about:
        "Dr.S.N.N.Pandit was the founder head of the statistics department in Dibrugarh University, Assam. Dr.Pandit gave the idea of ‘Advanced Academic Center’ in 2004 as a forum to generate creative ideas involving faculty and students at the time of his visit to GRIET in 2003-04 when he had  motivated faculty towards research. Dr.Pandit has also supervised 35 Ph.D scholars in variety of domains. He was the founder director of Center Of Quantitative Methods at Osmania University.",
      image:
        "https://res.cloudinary.com/aacgriet/image/upload/v1664100148/AAC-web/mentors/pandith_s8bqhh.jpg",
    },
    {
        id: "2",
        name: "Dr. Satteluri R.K.Iyengar",
        about:
          "Dr.S.R.K.Iyengar was professor at IIT Delhi in the department of Mathematics for over 38 years.Dr.Iyengar worked as a post-doctoral fellow at the Oxford University Computing Laborator,U.K.He is the author of over half a dozen text books,which have won industrial acclaims. Dr.Iyengar served GRIET between 2004 to 2012 with distinction. he brought in good academic culture in the institute. he was the first Dean,Academic Affairs at GRIET. Dr.Iyengar is one of the pillars of Advanced Academic Center at GRIET along with DR.S.N.N.Pandit.",
        image:
          "https://res.cloudinary.com/aacgriet/image/upload/v1664100147/AAC-web/mentors/iyengar_dmrolm.jpg",
      },
];