// Import Swiper React components
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";
import "swiper/css";
import {
  Box,
  Container,
  Heading,
  Text,
  Stack,
  Center,
  Flex,
  Image,
} from "@chakra-ui/react";
import Data from "../Data/Advisor";
import { Mousewheel, Keyboard } from "swiper";

const Advisory = () => {
  return (
    <>
      <Box my={20}>
        <Container my={20}>
          <Center>
            <Heading fontFamily={'merriweather'} fontSize={"3rem"}>ADVISORS</Heading>
          </Center>
        </Container>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          grabCursor={true}
          mousewheel={true}
          keyboard={true}
          modules={[ Mousewheel, Keyboard]}
        >
          {Data.map((item) => (
            <SwiperSlide key={item.id}>
              <div className="carousel-item active">
                <div className="container-fluid">
                  <Container
                    maxW={"5xl"}
                    height={{ base: "auto", md: "25rem" }}
                  >
                    <Stack
                      align={"center"}
                      spacing={{ base: 8, md: 10 }}
                      py={{ base: 3, md: 5 }}
                      ml={{ base: 3, md: 10 }}
                      direction={{ base: "column-reverse", md: "row" }}
                    >
                      <Flex
                        flex={2}
                        justify={"center"}
                        align={"center"}
                        position={"relative"}
                        py={{ base: 3, md: 5 }}
                        ml={{ base: 3, md: 10 }}
                        w={"full"}
                      >
                        <Box
                          position={"relative"}
                          height={"100%"}
                          rounded={"2xl"}
                          width={"full"}
                          overflow={"hidden"}
                          color={"white"}
                        >
                          <Heading textAlign={'center'} fontFamily={'meriweather'} fontSize={"2rem"} py={3}>
                            {item.name}
                          </Heading>
                          <Heading textAlign={'center'} fontFamily={'meriweather'} fontSize={"1rem"} py={3}>
                            {item.occupation}
                          </Heading>
                          <Text textAlign={'justify'} fontFamily={'poppins'} >{item.about}</Text>
                        </Box>
                      </Flex>
                      <Flex
                        flex={2}
                        justify={"center"}
                        align={"center"}
                        position={"relative"}
                        w={"full"}
                        py={{ base: 3, md: 5 }}
                        ml={{ base: 3, md: 10 }}
                      >
                        <Box
                          position={"relative"}
                          height={"100%"}
                          width={"full"}
                          overflow={"hidden"}
                          align={"center"}
                          className="container-fluid"
                        >
                          <Image
                            alt={"Hero Image"}
                            fit={"cover"}
                            align={"center"}
                            w={"20rem"}
                            h={"20rem"}
                            src={item.image}
                          />
                        </Box>
                      </Flex>
                    </Stack>
                  </Container>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </>
  );
};

export default Advisory;

