import React from "react";
import { Center, Heading, Box } from "@chakra-ui/react";
import NewsCard from "./newscard";
import { Grid } from "@chakra-ui/react";
import { graphql, useStaticQuery } from "gatsby";

const NewsList = ({ data }) => {

	const Data = useStaticQuery(graphql`
		{
			allSanityNews(sort: {fields: publishedAt, order: DESC}) {
				nodes {
					title
					slug {
						current
					}
					publishedAt
					_rawBody
					id
				}
			} 
		}
	`);
	const a = Data.allSanityNews.nodes;
	return (
		<>
			<Center mt={19} mb={10}>
				<Heading fontFamily={'merriweather'} fontWeight={'bold'} mt={9} ml={-10} fontSize={"3rem"}>
					NEWS @AAC
				</Heading>
			</Center>
			<Box maxW={"6xl"} mx={"auto"} justifyContent={"center"}>
				<Box direction={{ base: "column", md: "row" }}>
					<Grid
						h="auto"
						mb={10}
						color="white"
						templateRows={{ base: "repeat(3, 1fr)", md: "repeat(1, 1fr)" }}
						templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
						gap={5}
						justifyItems="center"
					>
						{a.map((i) => {
							return <NewsCard key={i.title}  news={i} />;
						})}
					</Grid>
				</Box>
			</Box>
		</>
	);
};

export default NewsList;
