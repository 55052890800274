export default [
  {
    id: "1",
    name: "Dr. Jandhyala N Murthy",
    occupation: "DIRECTOR, GRIET",
    about:
      "Dr. Jandhyala N Murthy after voluntarily taking retirement from the IAF as a Wing Commander in 2001,immediately joined as a professor in the Department of Mechanical Engineering at GRIET.Later on taking up the position as the Director of GRIET since 31 January 2018, after a successful tenure as the Principal of GRIET since March 2004.His areas of interest span the Thermal Engineering domain, Gas Turbine Combustion Chambers, simulation and education.",
    image:
      "https://res.cloudinary.com/aacgriet/image/upload/v1664100146/AAC-web/mentors/Dr-Jandhyala-Murthy_tgqu8z.jpg",
  },
  {
    id: "2",
    name: "Dr. J Praveen",
    occupation: "PRINCIPAL, GRIET",
    about:
      "Dr. J Praveen has contributed to a major research areas in Power Electronics and has published more than 80 research papers in reputed International and National Journals and Conferences.He is Registered as a  PhD Guide at Department of Electrical and Electronics Engineering, J.N.T University.Along with his other achievements he also received  International Certification on 'High Impact Teaching Skills' by Dale Carnegie & Associates Inc. Trainers (USA), Mission 10x, Wipro Technologies. ",
    image:
      "https://res.cloudinary.com/aacgriet/image/upload/v1664100147/AAC-web/mentors/jpraveen_mzj4fp.jpg",
  },
  // {
  //   id: "3",
  //   name: "M.G.Sekharam",
  //   occupation: "CHIEF ADMINISTRATIVE OFFICER,GRIET",
  //   about:
  //     "Mr. M.G. Sekaram joined GRIET as Chief Administrative Officer on June 27th,2019 after taking voluntary retirement from Railways.He is very passionate about teaching and had been teaching Railway staff for past 27years in various fora.",
  //   image:
  //     "https://res.cloudinary.com/aacgriet/image/upload/v1664100150/AAC-web/mentors/mgsekharam_nvuywr.png",
  // },
  {
    id: "4",
    name: "Dr. Mamidi Kiran Kumar",
    occupation: "DEAN, AAC",
    about:
      "Dr. Mamidi Kiran Kumar, an Associate Professor in the Department of Artificial Intelligence and Machine Learning Engineering at Gokaraju Rangaraju Institute of Engineering and Technology (GRIET) in Hyderabad, is a Rajeev Gandhi National Fellowship awardee. Specializing in Area-based Localization Techniques for Wireless Sensor Networks. With over a decade of academic and research experience, he's proficient in teaching B.TECH and M.TECH students, and has evaluated Graduate Level Examinations.",
    image:
      "https://res.cloudinary.com/dltvvelmx/image/upload/v1694781872/kiran1610_hhgs1v.jpg",
  },
  {
    id: "5",
    name: "Dr. Arvind Vishnubhatla",
    occupation: "PROFESSOR, ECE GRIET",
    about:
      "Dr. Arvind Vishnubhatla is currently working as Professor at GRIET.His areas of research and interests include Signal Processing, Embedded Systems, System Design. He worked on GSM switch Microlite Development of a Distributed digital control system for the automation of Steel plants.",
    image:
      "https://res.cloudinary.com/aacgriet/image/upload/v1664100146/AAC-web/mentors/aravind_gnf36b.jpg",
  },
  {
    id: "6",
    name: "Dr. G.V.K. Madhav",
    occupation:
      "SYSTEMS ENGINEER EG RND, HEWLETT PACKARD ENTERPRISE, BANGALOR",
    about:
      "Dr. G.V.K.Madhav works for HPE(Hewlett-Packard Enterprise) in the HPC (High Performance Computing) RnD division. For his Master's thesis he worked using GPUs for improving the performance of LiFE(Linear Fascicle Evaluation) software got published as a poster at “OHBM (Organization for Human Brain Mapping) 2018 Conference.",
    image:
      "https://res.cloudinary.com/aacgriet/image/upload/v1664100147/AAC-web/mentors/madhav1_fuuehs.jpg",
  },
  {
    id: "7",
    name: "Mr. Satish Yellanki",
    occupation:
      "CORPORATE TRAINER AT SATISH GROUP OF INSTITUTE OF SOFTWARE SOLUTIONS",
    about:
      "Mr. Satish is a corporate specialist with an experience of about 22 years in the industry. He has provided solutions to diversified domain in health care, insurance, educational and hospitality. He has trained a vast no. of students and industrial professionals in various IT and business domains. With Specialization in System analysis and solution based architecture development and consulting",
    image:
      "https://res.cloudinary.com/aacgriet/image/upload/c_scale,h_400,w_400/v1664100153/AAC-web/mentors/sss_sv2h2p.jpg",
  },
  {
    id: "8",
    name: "Mr. Pradeep Palelli",
    occupation: "THE CO-FOUNDER AND CEO OF THANOS TECHNOLOGIES PVT LTD.",
    about:
      "Mr. Pradeep brings 10 years of professional experience that includes 6 years of running start-ups. Pradeep is responsible for business and operations at Thanos. In his previous stints at Efficient Carbon and Zolt Energy, he handled Operations, Service and Sales in various capacities.",
    image:
      "https://res.cloudinary.com/aacgriet/image/upload/v1664100150/AAC-web/mentors/pradeepp_j0e7p3.jpg",
  },
  {
    id: "9",
    name: "Dr. Naresh Kumar Mallenahalli",
    occupation: "SCIENTIST AT NRSC",
    about:
      "Dr. Naresh works in the software group of satellite data processing area at National Remote Sensing Centre (ISRO), Hyderabad.Some of his current research interests include mathematical modeling and simulations, computational intelligence, statistical machine learning and so on.",
    image:
      "https://res.cloudinary.com/aacgriet/image/upload/v1664100152/AAC-web/mentors/naresh_x933qn.png",
  },
  {
    id: "10",
    name: "Shri Kidambi Seshadri",
    occupation: "SCIENTIST AT NRSC ISRO/DOS",
    about:
      "Shri Kidambi Seshadri was the recipient for ‘ISRO award’ for 2007 under RGNDWM project.He was working as the project manager in preparation of state-of-art groundwater prospects maps on 1:50,000 scale using Remote Sensing (RS) and Geographical Information System (GIS) techniques under RGNDWM.He was also the head of Mineral Exploration Geo-environmental Studies (MEGS) & Scientist ‘SG’, Geosciences Group, Remote Sensing Application Area (RSA), NRSC ISRO/DOS, Hyderabad.",
    image:
      "https://res.cloudinary.com/aacgriet/image/upload/v1664100151/AAC-web/mentors/seshadri_kdwupb.jpg",
  },
];
