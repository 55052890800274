import React from "react";
import "./newscard.css";
import {
	GridItem,
	Stack,
	Heading,
	Avatar,
	Box,
	Center,
	Text,
	Button,
} from "@chakra-ui/react";
import { Link } from "gatsby";

const newscard = ({ news, index }) => {
	const path = news.id;

	return (
		<>
			<GridItem colSpan={1}>
				{/* <Stack direction={"column"}>
					<Box bg={"#1A1A1A"} p={10}>
						<Heading fontWeight={490} fontSize={"1.3rem"}>
							Advanced Academic Center
						</Heading>
						<Text fontSize={"1.1rem"} maxW={"180px"}>
							{news.title}
						</Text>
					</Box>
					<Box h={"100%"} bg={"#62676B"}>
						<Box alignContent={'justify'} p={5} >
							<Text fontSize={"1.1rem"} maxW={"180px"}>
								{news.slug.current}
							</Text>
						</Box>
						<Stack direction={"row"} p={5}>
							<Box>
								<Link to={`/News/${path}`}>
									<Button color={"black"}>
										Know More <AiOutlineArrowRight className="arrow" />
									</Button>
								</Link>
							</Box>
							<Box>
								<Button color={"black"}>
									{news.publishedAt.substring(0, 10)}
								</Button>
							</Box>
						</Stack>
					</Box>
				</Stack> */}

				{/* another card */}

				<Center py={6}>
					<Box
						maxW={"320px"}
						w={"full"}
						bg={"black"}
						boxShadow={"2xl"}
						rounded={"lg"}
						p={6}
						textAlign={"center"}
					>
						<Avatar
							size={"xl"}
							src={
								"https://res.cloudinary.com/aacgriet/image/upload/v1664100142/AAC-web/icons/AAC_biqbat.svg"
							}
							alt={"Avatar Alt"}
							mb={4}
							pos={"relative"}
						/>
						<Heading fontFamily={'merriweather'} fontSize={"xl"} py={5} color={"white"}>
							{news.title}
						</Heading>
						<Text fontFamily={'poppins'} textAlign={"center"} color={"gray.500"} px={3}>
							{news.slug.current.substring(0, 80)}....
						</Text>
						<Stack mt={8} direction={"row"} spacing={4}>
							<Link to={`/News/${path}`}>
								<Button
									flex={2}
									fontSize={"sm"}
									rounded={"full"}
									bg={"black"}
									color={"white"}
									_hover={{
										bg: "gray.900",
									}}
									_focus={{
										bg: "gray.500",
									}}
								>
									Know More
								</Button>
							</Link>

							<Button
								flex={1}
								fontSize={"sm"}
								rounded={"full"}
								color={"white"}
								bg={"gray.500"}
								_hover={{
									bg: "gray.200",
									color: "black",
								}}
								_focus={{
									bg: "gray.200",
									color: "black",
								}}
							>
								{news.publishedAt.substring(0, 10)}
							</Button>
						</Stack>
					</Box>
				</Center>
			</GridItem>
		</>
	);
};

export default newscard;
