import {
	Box,
	chakra,
	Container,
	Stack,
	Text,
	Image,
	Flex,
	VStack,
	Button,
	Heading,
	SimpleGrid,
	StackDivider,
	useColorModeValue,
	VisuallyHidden,
	List,
	ListItem,
} from "@chakra-ui/react";
import React from "react";

const SmartGlove = () => {
	return (
		<>
			<Box boxShadow={"2xl"} bg={"blue.100"} p={10}>
				<Container maxW={"5xl"} mt={20}>
					{/* <Flex>
					<Image
						rounded={"md"}
						alt={"product image"}
						src={
							"https://images.unsplash.com/photo-1596516109370-29001ec8ec36?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyODE1MDl8MHwxfGFsbHx8fHx8fHx8fDE2Mzg5MzY2MzE&ixlib=rb-1.2.1&q=80&w=1080"
						}
						fit={"cover"}
						align={"center"}
						w={"100%"}
						h={{ base: "100%", sm: "400px", lg: "500px" }}
					/>
				</Flex> */}
					<Stack spacing={{ base: 6, md: 10 }}>
						<Box as={"header"}>
							<Heading
								fontFamily={"meriweather"}
								lineHeight={1.1}
								fontWeight={600}
								fontSize={{ base: "2xl", sm: "3xl", lg: "4xl" }}
							>
								A SMART GLOVE FOR RECOGNIZING AND COMMUNICATING SIGNLANGUAGE AND
								ASSOCIATED METHOD THEREOF.
							</Heading>
							{/* <Text
							color={useColorModeValue("gray.900", "gray.400")}
							fontWeight={300}
							fontSize={"2xl"}
						>
							Description/ slug
						</Text> */}
						</Box>

						<Stack
							spacing={{ base: 4, sm: 6 }}
							direction={"column"}
							divider={
								<StackDivider
									borderColor={useColorModeValue("gray.200", "gray.600")}
								/>
							}
						>
							<Box fontFamily={"poppins"}>
								<Text
									fontSize={{ base: "16px", lg: "18px" }}
									color={useColorModeValue("yellow.500", "yellow.300")}
									fontWeight={"500"}
									textTransform={"uppercase"}
									mb={"4"}
								>
									Patent filed by:
								</Text>

								<SimpleGrid columns={{ base: 2, md: 2 }} spacing={3}>
									<Text>Jashwanth Kranthi Bopanna</Text>
									<Text>Santosh Sanjeev</Text>
									<Text>Bharath Varma Kantheti</Text>
									<Text>Gowtham Sai Ponnekanti</Text>
									<Text>Suhas Gangireddy</Text>
									<Text>G Pradeep Reddy</Text>
								</SimpleGrid>
							</Box>
							<Box fontFamily={"poppins"}>
								<Text
									fontSize={{ base: "16px", lg: "18px" }}
									color={useColorModeValue("yellow.500", "yellow.300")}
									fontWeight={"500"}
									textTransform={"uppercase"}
									mb={"4"}
								>
									Patent Details
								</Text>

								<List spacing={2}>
									<ListItem>
										<Text as={"span"} fontWeight={"bold"}>
											Patent Office:
										</Text>{" "}
										India
									</ListItem>
									<ListItem>
										<Text as={"span"} fontWeight={"bold"}>
											DATE:
										</Text>{" "}
										03 SEP 2020
									</ListItem>
									<ListItem>
										<Text as={"span"} fontWeight={"bold"}>
											Patent Application:
										</Text>{" "}
										202041038106
									</ListItem>
									<ListItem>
										<Text as={"span"} fontWeight={"bold"}>
											Status
										</Text>{" "}
										Published Online
									</ListItem>
								</List>
							</Box>
						</Stack>
					</Stack>
				</Container>
				<Container maxW={"5xl"}>
					{/* <Stack py={"3"}>
						<Heading
							fontSize={{ base: "22px", lg: "28px" }}
							color={useColorModeValue("yellow.500", "yellow.300")}
							fontWeight={"500"}
							textTransform={"uppercase"}
							mb={"4"}
							fontFamily={"meriweather"}
						>
							Patent Description
						</Heading>
						<Text fontFamily={"poppins"} fontSize={"1.2rem"} fontWeight={"500"}>
							Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
							nonumy eirmod tempor invidunt ut labore Lorem ipsum dolor sit
							amet, consectetur adipisicing elit. Ad aliquid amet at delectus
							doloribus dolorum expedita hic, ipsum maxime modi nam officiis
							porro, quae, quisquam quos reprehenderit velit? Natus, totam.
						</Text>
					</Stack> */}
				</Container>
			</Box>
		</>
	);
};

export default SmartGlove;
