import { Box, Container, Heading, Text } from "@chakra-ui/react";
import React from "react";
import Layout from "../../components/Layout";
import Swipers from "./../../components/Events component/EventDetails/Swipers";

const CyberSecuitySeminar = () => {
	return (
		<Layout>
			<Box my={10}>
				<Heading
					fontSize={"3rem"}
					justifyContent={"center"}
					textAlign={"center"}
				>
					All India Seminar on “Cyber Security and Image Processing”
				</Heading>
			</Box>

			<Swipers
				img1={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085800/AAC-web/news_events/Cyber/seminar5_dqqcl6.jpg"
				}
				img2={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085800/AAC-web/news_events/Cyber/seminar4_dwaux4.jpg"
				}
				img3={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085800/AAC-web/news_events/Cyber/seminar2_fmf5un.jpg"
				}
				img4={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085799/AAC-web/news_events/Cyber/seminar3_i9q4eu.jpg"
				}
				img5={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085799/AAC-web/news_events/Cyber/seminar1_lz6zzy.jpg"
				}
			/>
			<Box>
				<Container maxW={"5xl"} py={10}>
					<Heading fontFamily={"meriweather"} fontSize={"2rem"}>
						All India Seminar on “Cyber Security and Image Processing”
					</Heading>
					<Text fontFamily={"poppins"} py={5}>
						A two day All India Seminar on ‘Cyber Security and Image Processing”
						was held on 10th and 12th August.The importance of cyber security
						was highlighted with supporting test cases given by the
						Guest-of-Honor. Followed by the applications of cyber technology and
						the further advancements in it .
						<br />
						<br />
						Using the technology in the right way was encouraged, they gave a
						brief about visual cryptography which involves the segmentation of
						information into mutually exclusive and individually random looking
						components and sending them over different paths.The seminar enabled
						students to gain knowledge on various aspects of the Electronics
						Industry.
					</Text>
				</Container>
			</Box>
		</Layout>
	);
};

export default CyberSecuitySeminar;
