import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Anima from "./../assets/45913-coming-soon.json";
import Lottie from "lottie-web";
import {  Button, Box, Center, Stack } from "@chakra-ui/react";

// markup
const NotFoundPage = () => {
	let animate = React.createRef();

	React.useEffect(() => {
		const anim = Lottie.loadAnimation({
			container: animate.current,
			animationData: Anima,
		});
		anim.setSpeed(2);
	}, []);
	return (
		<Layout>
			<Center>
				<Stack direction={"column"}>
					<Center>
						<Link to={"/"}>
							<Button
								rounded={"full"}
								px={6}
								py={7}
								mt={9}
								colorScheme={"white"}
								bg={"#172E7C"}
								_hover={{ bg: "blue.500" }}
							>
								Go to Home
							</Button>
						</Link>
					</Center>
					<Box className="animLoad" ref={animate} />
				</Stack>
			</Center>
		</Layout>
	);
};

export default NotFoundPage;
