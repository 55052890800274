import { Box, Container, Heading, Text } from "@chakra-ui/react";
import React from "react";
import Layout from "../../components/Layout";
import Swipers from "./../../components/Events component/EventDetails/Swipers";

const Labstart = () => {
	return (
		<Layout>
			<Box my={10}>
				<Heading
					fontSize={"3rem"}
					justifyContent={"center"}
					textAlign={"center"}
				>
					AAC LAB INAUGURATION
				</Heading>
			</Box>

			<Swipers
				img1={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085802/AAC-web/news_events/aac%20lab%20inaugural/inauguration7_gw5x8k.jpg"
				}
				img2={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085802/AAC-web/news_events/aac%20lab%20inaugural/inauguration1_b2oyum.jpg"
				}
				img3={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085802/AAC-web/news_events/aac%20lab%20inaugural/inauguration8_rmwzso.jpg"
				}
				img4={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085802/AAC-web/news_events/aac%20lab%20inaugural/inauguration3_ndi6na.jpg"
				}
				img5={
					"https://res.cloudinary.com/aacgriet/image/upload/v1666085801/AAC-web/news_events/aac%20lab%20inaugural/inauguration2_qgnkgz.jpg"
				}
			/>
			<Box>
				<Container maxW={"5xl"} py={10}>
					<Heading fontFamily={"meriweather"} fontSize={"2rem"}>
						Creativity is thinking up new things. Innovation is doing new
						things.
					</Heading>
					<Text fontFamily={"poppins"} py={5}>
						AAC stands for learning through exploration, experimentation and
						experience. To create a novel environment to foster scientific
						temper, innovation and creativity amongst students , the committee
						set up a Lab in room number 3614 in the Block 3 for conducting
						conclaves and to carry out research level projects.
						<br />
						<br />
						Along with all the dignitaries, Dr.M.G.Sekharam sir motivated the
						students to indulge in more hands-on-experience in the lab and
						advised the mentors of AAC to turn the classroom environment into a
						Laboratory experience of learning.Boosting their morale Dr.J.Praveen
						sir urged them to dare to dream beyond the mediocre. Dr.S.Ramamurthy
						sir, expressed his gratitude towards the college management for the
						remarkable gesture of aiding the AAC students with the latest
						technology and urged the students to make great use of it.
					</Text>
				</Container>
			</Box>
		</Layout>
	);
};

export default Labstart;
