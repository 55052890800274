// Import Swiper React components
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {
	Box,
	Container,
	Heading,
	Text,
	Center,
	Image,
} from "@chakra-ui/react";
import Data from "../Data/corecommitteaac";
import { Mousewheel, Keyboard } from "swiper";

const Advisory = () => {
	return (
		<>
			<Box my={20}>
				<Container my={20}>
					<Center>
						<Heading fontSize={"3rem"} fontFamily={'merriweather'}>CORE COMMITTEE</Heading>
					</Center>
				</Container>
				<Swiper
					spaceBetween={50}
					breakpoints={{
						10: {
							slidesPerView: 1,
						},
						400: {
							slidesPerView: 1,
						},
						768: {
							slidesPerView: 3,
						},
						1000: {
							slidesPerView: 4,
						}
					}}
					grabCursor={true}
					mousewheel={true}
					keyboard={true}
					modules={[Mousewheel, Keyboard]}
				>
					{Data.map((item) => (
						<SwiperSlide key={item.Id}>
							<Center maxW={"5xl"} height={{ base: "auto", md: "25rem" }}>
								<Cards
									img={item.Image}
									name={item.Name}
									designation={item.Designation}
								/>
							</Center>
						</SwiperSlide>
					))}
				</Swiper>
			</Box>
		</>
	);
};

export default Advisory;

const Cards = ({ img, name, designation }) => {
	return (
		<Box
			maxW={{ base: "200px", sm: "250px", md: "300px" }}
			boxShadow={"2xl"}
			rounded={"lg"}
			pos={"relative"}
			zIndex={1}
			_hover={{
				cursor: "pointer",
				transition: "all .3s ease",
				transform: "scale(1.03)",
			}}
		>
			<Center>
				<Image
					rounded={"xl"}
					height={"20rem"}
					width={"20rem"}
					boxSize="90%"
					src={img}
					boxShadow={"2xl"}
				/>
			</Center>
			<Center pt={"1rem"}>
				<Heading fontSize={"1rem"}>{name}</Heading>
			</Center>
			<Center pt={"0.6rem"} pb={"0.9rem"}>
				<Text>{designation}</Text>
			</Center>
		</Box>
	);
};
