import React from "react";
import {
  Box,
  Text,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Image,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";

const PublicationsCard = () => {
  return (
    <div>
      <Box pt={20}>
        <Center>
          <Heading fontFamily={"merriweather"} pb={20}>
            PUBLICATIONS
          </Heading>
        </Center>
        <SimpleGrid
          columns={{ base: "1", md: "3", lg: "4" }}
          spacingX="40px"
          spacingY="50px"
          mx={10}
          p={10}
        >
          <Center>
            <Center display={"flex"} flexDirection={"column"}>
              <Cards
                img={
                  "https://res.cloudinary.com/aacgriet/image/upload/v1730538693/AAC-web/publications/publications2023pics/wine.png"
                }
                name={"Ensemble–Based Wine Quality Detection....."}
              />

              <Center mt={4}>
                <Button
                  fontSize={"sm"}
                  rounded={"full"}
                  bg={"blue.400"}
                  color={"white"}
                  boxShadow={
                    "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                  }
                  _hover={{
                    bg: "blue.500",
                  }}
                  _focus={{
                    bg: "blue.500",
                  }}
                >
                  <Basicmodal
                    text="Explore"
                    title="Ensemble–Based Wine Quality Detection using Hybrid Machine Learning Models"
                    abstract='This paper proposes a novel ensemble learning method for accurately predicting wine quality, a crucial factor influencing market value and consumer satisfaction. The study combines four base machine-learning models: Random Forest, Logistic Regression, Support Vector Machines, and Gradient Boosting Machine. The models are optimized using Grid Search and combined via a Neural Network meta-classifier.
The ensemble model was trained and tested on a dataset from Kaggle containing 1,599 instances of red wine data, each with 11 physicochemical input variables. Preprocessing involved standardizing features and transforming the target variable into categorical classes ("perfect," "good," "average," "bad," and "inedible").
The proposed ensemble model achieved an accuracy of 88.44%, exceeding the performance of individual base models. This result demonstrates the effectiveness of combining diverse models to capture complex relationships between wine properties and quality. The studys findings offer valuable insights for the wine industry by enabling improved quality control and informed decision-making regarding production, pricing, and marketing strategies.
'
                    author1="Dodda Abhiram"
                    author2="Siddharth Mahesh Balijepally"
                    author3="Ekantha Sai Sundar"
                    publc="International Journal of Engineering Research and Technology(IJERT), ISSN: 2278-0181, Vol. 13 Issue 01, August 2024"
                  />
                </Button>
              </Center>
            </Center>
          </Center>
          <Center>
            <Center display={"flex"} flexDirection={"column"}>
              <Cards
                img={
                  "https://res.cloudinary.com/aacgriet/image/upload/v1730538693/AAC-web/publications/publications2023pics/OCT.png"
                }
                name={"Deep Learning and OCT Imaging: A Novel Ensemble...."}
              />

              <Center mt={4}>
                <Button
                  fontSize={"sm"}
                  rounded={"full"}
                  bg={"blue.400"}
                  color={"white"}
                  boxShadow={
                    "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                  }
                  _hover={{
                    bg: "blue.500",
                  }}
                  _focus={{
                    bg: "blue.500",
                  }}
                >
                  <Basicmodal
                    text="Explore"
                    title="Deep Learning and OCT Imaging: A Novel Ensemble Approach for Eye Disease Diagnosis"
                    abstract="This paper explores the effectiveness of an ensemble approach using Convolutional Neural Networks (CNNs) for classifying ocular diseases from retinal Optical Coherence Tomography (OCT) images. Three pre-trained CNN architectures—VGG16, InceptionV3, and InceptionResNetV2—are fine-tuned on an OCT image dataset and their predictions are combined using a max voting ensemble technique. The dataset consists of 84,484 images categorized into four classes: CNV, DME, DRUSEN, and NORMAL.
The ensemble model achieved a remarkable accuracy of 98.86%, outperforming individual models. This result highlights the power of ensemble learning in handling complex patterns within OCT images and its potential for enhancing diagnostic accuracy in medical imaging applications. Future research directions include exploring diverse deep learning models and incorporating attention mechanisms to further improve performance.
"
                    author1="Dodda Abhiram"
                    author2="R.Aruna Flarence"
                    author3="K.Anuradha"
                    author4="V.Srilakshmi"
                    publc="4th International Conference on Cognitive & Intelligent Computing (ICCIC-2024), Springer Conference"
                  />
                </Button>
              </Center>
            </Center>
          </Center>
          <Center>
            <Center display={"flex"} flexDirection={"column"}>
              <Cards
                img={
                  "https://res.cloudinary.com/aacgriet/image/upload/v1730539764/AAC-web/publications/publications2023pics/skin.png"
                }
                name={"A Novel Deep-Learning Based Classification...."}
              />

              <Center mt={4}>
                <Button
                  fontSize={"sm"}
                  rounded={"full"}
                  bg={"blue.400"}
                  color={"white"}
                  boxShadow={
                    "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                  }
                  _hover={{
                    bg: "blue.500",
                  }}
                  _focus={{
                    bg: "blue.500",
                  }}
                >
                  <Basicmodal
                    text="Explore"
                    title="A Novel Deep-Learning Based Classification of Skin Diseases"
                    abstract="This paper presents a novel deep convolutional neural network (CNN) model for the classification of eight common skin diseases. The model uses the EfficientNetB3 architecture for feature extraction and incorporates transfer learning from the ImageNet dataset.
The study addresses the issue of dataset imbalance through a combination of data augmentation and under-sampling. This approach ensures the model generalizes well and does not overfit on the majority class. The balanced dataset contains an equal number of images (4000) for each of the eight classes.
The model achieved a high accuracy of 95.27% in classifying skin disease images, demonstrating the effectiveness of deep CNNs for this task. The results indicate a significant improvement compared to models trained on imbalanced datasets. This research highlights the potential of deep learning in assisting medical professionals with accurate and timely diagnosis of skin diseases
"
                    author1="Tabitha Indupalli"
                    author2="Singamsetty Aashrith"
                    author3="Dodda Abhiram"
                    author4="Vineeth Vudiga"
                    author5="Rayapuraju Srivatsav"
                    publc="5th IEEE Global Conference on Advancement of Technology(GCAT) 2024"
                  />
                </Button>
              </Center>
            </Center>
          </Center>
          <Center>
            <Center display={"flex"} flexDirection={"column"}>
              <Cards
                img={
                  "https://res.cloudinary.com/aacgriet/image/upload/v1730538693/AAC-web/publications/publications2023pics/plant.png"
                }
                name={"A Novel Two-Stage Deep Learning Framework..."}
              />

              <Center mt={4}>
                <Button
                  fontSize={"sm"}
                  rounded={"full"}
                  bg={"blue.400"}
                  color={"white"}
                  boxShadow={
                    "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                  }
                  _hover={{
                    bg: "blue.500",
                  }}
                  _focus={{
                    bg: "blue.500",
                  }}
                >
                  <Basicmodal
                    text="Explore"
                    title="A Novel Two-Stage Deep Learning Framework for Plant Disease Detection"
                    abstract="The two-stage approach you described in your abstract uses EfficientNetB3 and EfficientNetB5 as base models for transfer learning. These models were chosen for their efficiency and ability to extract relevant features. EfficientNetB3 is used in the first stage, which classifies the plant species, and EfficientNetB5 is used in the second stage, which classifies the disease.The second stage uses nine separate models for disease classification, each trained on a subset of the data for a specific plant species. The models are trained for 10 epochs, and the overall accuracy of the two-stage approach is 96.82%."
                    author1="Dodda Abhiram"
                    author2="Dr. K. Anuradha"
                    author3="Dr.V.Srilakshmi"
                    author4="K. Adilakshmi"
                    publc="5th IEEE Global Conference on Advancement of Technology(GCAT) 2024"
                  />
                </Button>
              </Center>
            </Center>
          </Center>
          <Center>
            <Center display={"flex"} flexDirection={"column"}>
              <Cards
                img={
                  "https://res.cloudinary.com/aacgriet/image/upload/v1668795374/AAC-web/publications/smart_posture_tauukb.jpg"
                }
                name={"Smart Posture Detection and Correction system..."}
              />

              <Center mt={4}>
                <Button
                  fontSize={"sm"}
                  rounded={"full"}
                  bg={"blue.400"}
                  color={"white"}
                  boxShadow={
                    "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                  }
                  _hover={{
                    bg: "blue.500",
                  }}
                  _focus={{
                    bg: "blue.500",
                  }}
                >
                  <Basicmodal
                    text="Explore"
                    title="Smart Posture Detection and Correction system using Skeletal Points Extraction"
                    abstract="This paper is intended to present a smart posture recognition and correction system. In specific, sitting in wrong posture for persistent period of time results in many health problems such as back pain, soreness, poor circulation, cervical pains and also decrease in eyesight in the long run. The proposed model makes use of real time skeletal points extraction. This system is based on computer vision and machine learning algorithms"
                    author1="J B V Prasad Raju, ECE, 16241A04D8"
                    author2="Yelma Chethan Reddy, ECE, 16241A04H9"
                    author3="Pradeep Reddy G"
                    publc="Advances in Decision Sciences, Image Processing, Security and Computer Vision (pp.177-181), January 2020,
                    DOI: 10.1007/978-3-030-24322-7_23"
                  />
                </Button>
              </Center>
            </Center>
          </Center>

          <Center>
            <Center display={"flex"} flexDirection={"column"}>
              <Cards
                img={
                  "https://res.cloudinary.com/aacgriet/image/upload/v1668795187/AAC-web/publications/descriptors_xq49ft.jpg"
                }
                name={"Optimum Number of Fourier Descriptors..."}
              />

              <Center mt={4}>
                <Button
                  fontSize={"sm"}
                  rounded={"full"}
                  bg={"blue.400"}
                  color={"white"}
                  boxShadow={
                    "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                  }
                  _hover={{
                    bg: "blue.500",
                  }}
                  _focus={{
                    bg: "blue.500",
                  }}
                >
                  <Basicmodal
                    text="Explore"
                    title="Optimum Number of Fourier Descriptors for Closed Boundary Retrieval"
                    abstract="In the post segmentation scenario, when objects in the scene have been extracted, the focus shifts to object identification. This can be achieved through shape or texture. Finding the object boundary has been a reliable means of shape description. Among the mathematical approximation techniques for shape analysis, Fourier descriptors have proven to approximate closed boundaries of objects quite well, albeit with some limitations. A statistical thresholding technique to restrict the number of descriptors for a reasonably good approximation of the target shape is explored and tested on some medical images. Encouraging results were obtained particularly when segmentation in the preprocessing stage was effectively carried out."
                    author1="Dr. Ramamurthy Suri, Dean AAC, Professor BSH"
                    author2="Gopi Krishna Pulluri, Asst.Professor, BSH                    "
                    author3="Keerthana Nakka, ECE, 17241A04G1"
                    publc="Gate to Computer Vision and Pattern Recognition vol. 2, no. 1, pp. 1-13, 2020
                    DOI:10.15579/gtcvpr.0201.001013"
                  />
                </Button>
              </Center>
            </Center>
          </Center>

          <Center>
            <Center display={"flex"} flexDirection={"column"}>
              <Cards
                img={
                  "https://res.cloudinary.com/aacgriet/image/upload/v1668795374/AAC-web/publications/health_yrxzq6.jpg"
                }
                name={
                  "Advanced Healthcare System using Artificial Intelligence"
                }
              />

              <Center mt={4}>
                <Button
                  fontSize={"sm"}
                  rounded={"full"}
                  bg={"blue.400"}
                  color={"white"}
                  boxShadow={
                    "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                  }
                  _hover={{
                    bg: "blue.500",
                  }}
                  _focus={{
                    bg: "blue.500",
                  }}
                >
                  <Basicmodal
                    text="Explore"
                    title="Advanced Healthcare System using Artificial Intelligence"
                    abstract="Matters like prescription and maintenance of health records hugely impact the efficiency of health care. Advanced technologies could be used to change the current scenario. This paper proposes a resourceful, web interface which enables access of medical records to the patients and a neural network model which predicts medication for ailments. The web interface acts as a medium between doctor and patient, allowing them to access the required information. The proposed method uses custom trained speech to text model and applies Natural Language Processing (NLP) on the acquired text, to provide the patient with a prescription. The proposed method further develops a neural network model which predicts the medication to be used by the patient, based on the symptoms. The proposed system achieved a Word Error Rate (WER) of 21.5% for the custom trained Speech to Text (STT) model. The AI bot used for medication prediction has achieved an accuracy of 88%."
                    author1="Pradeep Reddy G"
                    author2="Santosh Sanjeev, ECE, 18241A04T1"
                    author3="P Gowtham Sai, CSE, 18241A05X9"
                    publc="2021, 11th International Conference on Cloud Computing, Data Science & Engineering (Confluence)
                    DOI:10.1109/Confluence51648.2021.9377084"
                  />
                </Button>
              </Center>
            </Center>
          </Center>

          <Center>
            <Center display={"flex"} flexDirection={"column"}>
              <Cards
                img={
                  "https://res.cloudinary.com/aacgriet/image/upload/v1668795374/AAC-web/publications/fscurves_ivz29c.jpg"
                }
                name={"On Efficient Shape Retrieval Of Systematic Curves..."}
              />

              <Center mt={4}>
                <Button
                  fontSize={"sm"}
                  rounded={"full"}
                  bg={"blue.400"}
                  color={"white"}
                  boxShadow={
                    "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                  }
                  _hover={{
                    bg: "blue.500",
                  }}
                  _focus={{
                    bg: "blue.500",
                  }}
                >
                  <Basicmodal
                    text="Explore"
                    title="On Efficient Shape Retrieval Of Systematic Curves Using Fourier Descriptors"
                    abstract="Summarizing complex boundary shapes can be challenging. Approximation tools that already exist may require tweaking and refinement or novel approximation techniques need to be designed and tested for robustness. The problems arising in contexts like biological, satellite and medical imagery pose several challenging problems where object recognition becomes the focus of study. One of the means of recognizing objects is to describe their shapes. Boundary description is one of the means of summarizing shapes. Adding texture and color analysis to boundary is expected to take us closer to object recognition and understanding. Boundary of a shape can be modelled by spatial methods or through spectral approaches. Bilateral and radial symmetries are the popular areas of study. Since symmetry holds high level structural information, a study of symmetries can aid high level processing goals like segmentation and template matching to mention a few. The effectiveness of a near exact or exact reproduction of shape can be used for studying a variety of biological situations including leaf shape analysis and study of human parts. We focus on some shapes having bilateral symmetry, synthetic in nature, and try to retrieve a fair approximation using Fourier descriptors. We hope to extend this approach to some real-world problems where symmetries are observed. Three well known shape signatures viz. the centroid distance, area and cumulative angular functions are considered in this study. The performance of the cumulative angular function has been observed to be superior. This was tested on some shapes having bilateral symmetry and test results justify our findings."
                    author1="Dr.Ramamurthy Suri, Dean AAC, Professor BSH"
                    author2="Gopi Krishna Pulluri, Asst.Professor, BSH"
                    author3="Keerthana Nakka, ECE, 17241A04G1"
                    publc="International Journal on Emerging Technologies 11(3): 626-633(2020) May 2020"
                  />
                </Button>
              </Center>
            </Center>
          </Center>

          <Center>
            <Center display={"flex"} flexDirection={"column"}>
              <Cards
                img={
                  "https://res.cloudinary.com/aacgriet/image/upload/v1668795372/AAC-web/publications/morse_yy2n4d.jpg"
                }
                name={
                  "A two way communication system with Morse code Medium..."
                }
              />

              <Center mt={4}>
                <Button
                  fontSize={"sm"}
                  rounded={"full"}
                  bg={"blue.400"}
                  color={"white"}
                  boxShadow={
                    "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                  }
                  _hover={{
                    bg: "blue.500",
                  }}
                  _focus={{
                    bg: "blue.500",
                  }}
                >
                  <Basicmodal
                    text="Explore"
                    title="A two way communication system with Morse code Medium for people with Multiple Disabilities"
                    abstract="This paper proposes an innovative, Morse code based two-way communication system with four user-accessible modes. The four modes are determined to provide speech to Morse vibrations and Morse to speech conversion. The proposed system is embedded with touch, gesture, vibration, microphone, and speaker modules coupled with a microprocessing unit. An S-Morse dictionary convert Morse code pattern read from the touch sensor to voice and speech from microphone to Morse vibration. A gesture recognition model using the SVM algorithm is implemented for the selection of user-accessible modes. The system modes also send and receive text data from an android application through a cloud network. The received and sent data will be converted into vibrations of Morse code pattern and voice forms respectively. The proposed method is strengthened by validating Morse conversion, machine learning algorithm accuracy, gesture recognition accuracy, Morse time analysis for alphabets, word and gesture analysis for mode selection."
                    author1="Pradeep Reddy G"
                    author2="G Suhas, ECE, 18241A04Q0"
                    author3="B Jaswanth Kranthi, ECE, 18241A04P3"
                    author4="K Bharath Varma, ECE, 18241A04P0"
                    publc="2020 IEEE 7th Uttar Pradesh Section International Conference on Electrical, Electronics and Computer Engineering (UPCON)
                    DOI: 10.1109/UPCON50219.2020.9376479"
                  />
                </Button>
              </Center>
            </Center>
          </Center>

          <Center>
            <Center display={"flex"} flexDirection={"column"}>
              <Cards
                img={
                  "https://res.cloudinary.com/aacgriet/image/upload/v1668795373/AAC-web/publications/refarch_excq1y.jpg"
                }
                name={
                  "Reference Architecture for Intelligent Enterprise Solutions"
                }
              />

              <Center mt={4}>
                <Button
                  fontSize={"sm"}
                  rounded={"full"}
                  bg={"blue.400"}
                  color={"white"}
                  boxShadow={
                    "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                  }
                  _hover={{
                    bg: "blue.500",
                  }}
                  _focus={{
                    bg: "blue.500",
                  }}
                >
                  <Basicmodal
                    text="Explore"
                    title="Reference Architecture for Intelligent Enterprise Solutions"
                    abstract="Data in IT systems in enterprises have been growing at phenomenal pace. This has provided opportunities to run analytics to gather intelligence on key business parameters that enable them to provide better products and services to customers. While there are several Artificial Intelligence/Machine Learning (AI/ML) and Business Intelligence (BI) tools and technologies available in marketplace to run analytics, there is a need for an integrated view when developing intelligent solutions in enterprises. This paper progressively elaborates a reference model for enterprise solutions, builds an integrated view of data, information and intelligence components and presents a reference architecture for intelligent enterprise solutions. Finally, it applies the reference architecture to an insurance organization. The reference architecture is the outcome of experience and insights gathered from developing intelligent solutions for several organizations."
                    author1="Harish Rohan Kambhampaty, CSE, 19241A0518"
                    author2="Shankar Kambhampaty,
                    Director - Chief Technology Officer (CTO) for DXC Technology"
                    publc="World Academy of Science, Engineering and Technology International Journal of Computer and Information Engineering Vol:15, No:7, 2021"
                  />
                </Button>
              </Center>
            </Center>
          </Center>
        </SimpleGrid>
      </Box>
    </div>
  );
};

export default PublicationsCard;

const Cards = ({ img, name }) => {
  return (
    <Box
      maxW={{ base: "200px", sm: "250px", md: "300px" }}
      maxH={{ base: "350px", sm: "400px", md: "350px" }}
      boxShadow={"2xl"}
      rounded={"lg"}
      bg={"black"}
      pos={"relative"}
      zIndex={1}
      _hover={{
        cursor: "pointer",
        transition: "all .3s ease",
        transform: "scale(1.03)",
      }}
    >
      <Center display={"flex"} flexDirection={"column"} p={4}>
        <Image
          rounded={"xl"}
          height={"20rem"}
          width={"20rem"}
          boxSize="100%"
          src={img}
          boxShadow={"2xl"}
        />
        <Text p={5} color={"white"}>
          {name}
        </Text>
      </Center>
    </Box>
  );
};

function Basicmodal({
  text,
  title,
  author1,
  author2,
  author3,
  abstract,
  publc,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Center onClick={onOpen}>{text}</Center>

      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        size={"6xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader align={"center"}>
            <Heading fontFamily={"merriweather"} fontWeight={"bold"} mt={8}>
              {title}
            </Heading>
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <Center display={"flex"} flexDirection={"column"}>
              <Box mb="1rem" m={10}>
                <Center>
                  <Heading
                    fontFamily={"merriweather"}
                    fontWeight={"medium"}
                    m={8}
                  >
                    Abstract
                  </Heading>
                </Center>
                <Text fontFamily={"poppins"}>{abstract}</Text>
                <br />
                <br />
                <Center>
                  <Heading
                    fontFamily={"merriweather"}
                    fontWeight={"medium"}
                    m={8}
                  >
                    Author
                  </Heading>
                </Center>
                <Box fontFamily={"poppins"}>
                  <li>{author1} </li>
                  <li>{author2} </li>
                  <li> {author3}</li>
                </Box>
                <br />
                <br />
                <Center>
                  <Heading
                    fontFamily={"meriweather"}
                    fontWeight={"medium"}
                    m={8}
                  >
                    Publications
                  </Heading>
                </Center>
                <Text fontFamily={"poppins"}>{publc}</Text>
              </Box>
            </Center>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
