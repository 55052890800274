import React from "react";
import {
	Button,
	Flex,
	Box,
	Heading,
	Stack,
	Text,
} from "@chakra-ui/react";
import { Link } from "gatsby";
import Anima from "./../../assets/56757-drone-photography.json";
import Lottie from "lottie-web";

export default function SplitScreen() {
	let animate = React.createRef();

	React.useEffect(() => {
		const anim = Lottie.loadAnimation({
			container: animate.current,
			animationData: Anima,
		});
		anim.setSpeed(2);
	}, []);
	return (
		<Stack
			bg={"black"}
			minH={"100vh"}
			direction={{ base: "column", md: "row" }}
		>
			<Flex p={8} flex={1} align={"center"} justify={"center"}>
				<Stack spacing={6} w={"full"} maxW={"lg"}>
					<Heading fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}>
						<Text as={"span"} position={"relative"} color={"white"}>
							DRONES CLUB
						</Text>
						<br />{" "}
						<Text
							color={"blue.300"}
							as={"span"}
							fontSize={{ base: "2xl", md: "2xl", lg: "3xl" }}
						>
							Fly above and beyond!
						</Text>{" "}
					</Heading>
					<Text fontSize={{ base: "md", lg: "lg" }} color={"gray.500"}>
						- Provide insight into blooming drones field <br />
						- Train students in the field of drones <br />- Provide guidance on
						how to integrate various domain experience to provide viable
						solutions
					</Text>
					<Stack direction={{ base: "column", md: "row" }} spacing={4}>
						<Link to="/ComingSoon">
							<Button
								rounded={"full"}
								bg={"blue.400"}
								color={"white"}
								_hover={{
									bg: "blue.500",
								}}
							>
								Check In
							</Button>
						</Link>
						<Button rounded={"full"}>Started at: 22-04-2022</Button>
					</Stack>
				</Stack>
			</Flex>
			<Flex flex={1}>
				{/* <Image
					alt={"Login Image"}
					objectFit={"cover"}
					src={
						"https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
					}
				/> */}
				<Box className="animLoad" ref={animate} />
			</Flex>
		</Stack>
	);
}
